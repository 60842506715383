<template>
    <div class="content">
      <div class="content-header">
        <h1 class="content-title">
          Печатные материалы
        </h1>
      </div>
    <div class="hide-print print-settings">
      <AutosuggestMulti
          ref="account_id"
          :multiple="false"
          v-model="account_data"
          :value="account_data"
          name="account_id"
          text="Партнёр"
          url="/accounts/suggest?type=partner&limit=40"
          v-on:input="changeAccountIds"
      />

      <div class="radio">
        <label>
          <input type="checkbox" name="showAll" v-model="show_a4_v">
          <span></span>
          <div>А4 вертикальная</div>
        </label>
      </div>

      <div class="radio">
        <label>
          <input type="checkbox" name="showAll" v-model="show_a4_x3">
          <span></span>
          <div>А4 х3</div>
        </label>
      </div>

      <div class="radio">
        <label>
          <input type="checkbox" name="showBadge" v-model="showBadge">
          <span></span>
          <div>Бейджи</div>
        </label>
      </div>

      <div class="radio">
        <label>
          <input type="checkbox" name="showBadge" v-model="showMini">
          <span></span>
          <div>Маленькое расписание</div>
        </label>
      </div>

    </div>
      <template v-if="show">
        <div v-for="(a, k) in [account_data]" v-bind:key="k" class="d-flex d-flex-wrap">
          <div class="a4-v pb" v-show="show_a4_v">
            <div class="qr-logo"></div>
            <div class="qr-text">
              Путешествуйте вместе с нами!<br>
              <b class="bt-g">Экскурсии</b>
            </div>
            <div class="qr-body">
              <QRCodeVue3
                  :width="1200"
                  :height="1200"
                  :value="`https://vizit.tours?p=${a.value}`"
                  image="/android-chrome-512x512.png"
                  :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
                  :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 30 }"
                  :dotsOptions="{
                    type: 'rounded',
                    color: '#0b0b8f',
                    gradient: {
                      type: 'linear',
                      rotation: 0,
                      colorStops: [
                        { offset: 0, color: '#0b0b8f' },
                        { offset: 1, color: '#0b0b8f' },
                      ],
                    },
                  }"
                  :backgroundOptions="{ color: '#ffffff' }"
                  :cornersSquareOptions="{ type: 'dot', color: '#000000' }"
                  :cornersDotOptions="{ type: undefined, color: '#000000' }"
                  fileExt="png"
                  :download="false"
                  myclass="partner-qur"
                  imgclass="img-qr"
              />
              <div class="qr-manual">
                <p>
                  Наведите камеру  телефона на QR - код или посетите наш сайт <b>vizit.tours</b>,
                  чтобы посмотреть расписание на ближайшие экскурсии.
                </p>
                <p>
                  Укажите промокод: <b>S{{ a.value }}</b> и получите скидку.
                </p>
              </div>
            </div>
            <div>
              <div class="qr-cloud_text">
                Наши направления
              </div>
              <div class="qr-cloud">
                <i v-for="(tag, index) in tags" v-bind:key="index">
                  {{ tag }}
                </i>
              </div>
            </div>
          </div>

          <div class="a4-v_x3" v-show="show_a4_x3">
            <div v-for="n in 3" v-bind:key="n">
              <div class="a4-v">
                <div class="qr-logo"></div>
                <div class="qr-text">
                  Путешествуйте вместе с нами!<br>
                  <b class="bt-g">Экскурсии</b>
                </div>
                <div class="qr-body">
                  <QRCodeVue3
                      :width="1200"
                      :height="1200"
                      :value="`https://vizit.tours?p=${a.value}`"
                      image="/android-chrome-512x512.png"
                      :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
                      :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 30 }"
                      :dotsOptions="{
                type: 'rounded',
                color: '#0b0b8f',
                gradient: {
                  type: 'linear',
                  rotation: 0,
                  colorStops: [
                    { offset: 0, color: '#0b0b8f' },
                    { offset: 1, color: '#0b0b8f' },
                  ],
                },
              }"
                      :backgroundOptions="{ color: '#ffffff' }"
                      :cornersSquareOptions="{ type: 'dot', color: '#000000' }"
                      :cornersDotOptions="{ type: undefined, color: '#000000' }"
                      fileExt="png"
                      :download="false"
                      myclass="partner-qur"
                      imgclass="img-qr"
                  />
                  <div class="qr-manual">
                    <p>
                      Наведите камеру  телефона на QR - код или посетите наш сайт <b>vizit.tours</b>,
                      чтобы посмотреть расписание на ближайшие экскурсии.
                    </p>
                    <p>
                      Укажите промокод: <b>S{{ a.value }}</b> и получите скидку.
                    </p>
                  </div>
                </div>
                <div>
                  <div class="qr-cloud_text">
                    Наши направления
                  </div>
                  <div class="qr-cloud">
                    <i v-for="(tag, index) in tags" v-bind:key="index">
                      {{ tag }}
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </template>
      <div v-if="showBadge">

        <div>
          <Input name="bagePages" text="Кол-во страниц" type="number" required="true" v-model="bagePages" />
        </div>

        <div v-for="p in bagePages" v-bind:key="p" class="a4-v" style="background: #fff;display: flex;flex-wrap: wrap;flex-direction: row;justify-content: center;    align-items: self-start;">

          <template v-for="n in 8">
            <div class="bage-item" v-bind:key="n" v-if="names[(8*(p-1))+n]">
              <div class="bage-item_logo">
                <div class="qr-logo">
                  Туристическая компания
                </div>
              </div>
              <div class="bage-item_text" contenteditable="true">{{names[(8*(p-1))+n]}}</div>
              <div class="bage-item_subtext" contenteditable="true">Организатор экскурсий</div>
            </div>
          </template>

        </div>
      </div>
      <div v-if="showMini">
        <div style="margin: 20px 0 40px" class="hide-print">
          <Select
              text="Неделя"
              class="mb-0"
              id="period_t"
              name="period_t"
              required="true"
              :options="selectDates"
              track-by="0"
              label="1"
              v-model="currentDate"
          />
          <hr>
          <AutosuggestMulti
              :multiple="false"
              ref="excursion_point"
              v-model="point_data"
              name="excursion_point"
              text="Точка продажи"
              url="/misc/point"
              v-on:input="changePointIds"
          />
          <AutosuggestMulti
              :multiple="false"
              ref="excursion_point"
              v-model="order_seller_data"
              name="excursion_point"
              :text="`Кассир`"
              :url="`/accounts/suggest?type=staff&sub=3`"
              v-on:input="changeSellerIds"
          />
        </div>
        <div class="a4-h" :class="{big: currentDate === '2024-09-16'}">
          <div class="mini-box">
            <div style="margin: 0 0 2px 0;">
              <b contenteditable="true" style="font-size: 14px;">
                Ежедневно: морские прогулки, дельфинарий "Немо", джиппинг, квадроциклы, дайвинг.
              </b>
            </div>
            <Report key="report"
                    ref="report"
                    url="flights/report"
                    :def-params="{order: {field: 'excursion_id', order: 'desc'}, filters: {schedule_date: 'bw|' + period, excursion_publication: 'eq|enabled'}, limit: 500}" v-on:loading="reportLoading" v-on:done="reportLoaded">
              <Loading />
              <div v-if="error">
                {{error}}
              </div>
              <table v-else class="table-print" cellpadding="0" cellspacing="0" width="100%">
                <thead>

                <tr>
                  <td>
                    <h2 style="margin: 0 0 8px">
                      ТЭФ &laquo;Визит&raquo;
                    </h2>
                    <div>
                      <b contenteditable="true">{{ point_data.address || '' }}</b>
                    </div>
                  </td>
                  <td rowspan="2" class="vertical-text_td">
                    <div class="vertical-text x2">
                      Время<br>отправления
                    </div>
                  </td>
                  <td rowspan="2" class="vertical-text_td">
                    <div class="vertical-text x2">
                      Цена<br>взр/дет
                    </div>
                  </td>
                  <td rowspan="2" class="vertical-text_td">
                    <div class="vertical-text">
                      Доп. сбор
                    </div>
                  </td>
                  <td colspan="7" style="text-transform: uppercase;text-align: center">
                    <div style="display: flex;align-items: center;justify-content: center">
                      <div class="qr-pay" style="margin: 0 20px 0 0"></div>
                      <h3>
                        <template v-if="order_seller_data && order_seller_data.label">
                          <div contenteditable="true">
                           <div style="font-size: 22px;margin: 0 0 10px;">
                             {{formatPhone(order_seller_data.label.split('[')[1].replace(']', ''))}}
                           </div>
                            {{order_seller_data.label.split('[')[0]}}
                          </div>
                        </template>
                      </h3>
                    </div>
                  </td>
                  <td rowspan="2" class="vertical-text_td">
                    <div class="vertical-text">
                      Общее время
                    </div>
                  </td>
                </tr>
                <tr>
                  <td><b style="font-size: 14px">Сайт: vizit.tours</b></td>

                  <td v-for="(date, index) in dates" v-bind:key="index" style="text-align: center;text-transform: uppercase">
                    <b>{{convertDate(date, 'DD.MM')}}</b><br>
                    {{days[convertDate(date, 'd')]}}
                  </td>
                </tr>

                </thead>
                <tbody v-if="report && point_data && point_data.sub">
                <tr v-if="report.rows_count === 0">
                  <td colspan="16">
                    Не найдено.
                  </td>
                </tr>

                <template v-else>

                  <template v-for="(row, index) in report.rows">
                    <template v-if="row.excursion_linked === row.excursion_id && row.preparedDates">
                      <tr
                          v-if="row.excursion_linked === row.excursion_id && (point_data.sub === row.subdivision_id)"
                          v-bind:key="index"
                      >
                        <td>
                          <Input type="number" v-model="row.excursion_print_sort" class="rwer hide-print" text=" " name="tet[]" />
                          <contenteditable :contenteditable="true" tag="div" :no-html="false" v-model="row.excursion_print_title"></contenteditable>
                        </td>

                        <td class="bt-time">
                          <div class="time_fuck" v-for="(to, ri) in row.preparedDatesTime" v-bind:key="ri">
                              {{ to.n || to.o }}
                          </div>
                        </td>
                        <td>
                          <template v-if="row.excursion_prepay_allow === 'true'">
                            {{ row.excursion_prepay_adult }}/{{ row.excursion_prepay_children }}
                          </template>
                          <template v-else>
                            {{ row.excursion_price_adult }}/{{ row.excursion_price_children }}
                          </template>
                        </td>
                        <td>
                          <contenteditable :contenteditable="true" tag="div" :no-html="false" v-model="row.excursion_print_price" @returned="enterPressed"></contenteditable>
                        </td>
                        <td v-for="(date, index) in dates" v-bind:key="index" class="center">
                          <div class="time_fuck" v-for="(to, ri) in row.preparedDatesTime" v-bind:key="ri">
                            <template v-if="row.preparedDates && row.preparedDates[convertDate(date, 'YYYY-MM-DD')]">
                              <div>
                                <div v-for="(d, index) in row.preparedDates[convertDate(date, 'YYYY-MM-DD')]" v-bind:key="index">
                                  <i v-if="to.o === convertDate(d.date, 'HH:mm')" :data-time="convertDate(d.date, 'HH:mm')">☑</i>
                                </div>
                              </div>
                            </template>
                          </div>
                        </td>

                        <td style="text-align: center">{{ prepareDurationH(row.excursion_duration) }}</td>
                      </tr>

                      <template v-for="(row2, index) in compareSubdivision(row.excursion_id)">
                        <tr
                            v-if="row.excursion_id !== row2.excursion_id && row2.subdivision_id === point_data.sub"
                            v-bind:key="index"
                        >
                          <td>
                            <Input type="number" v-model="row2.excursion_print_sort" class="rwer hide-print" text=" " name="tet[]" />
                            <contenteditable :contenteditable="true" tag="div" :no-html="false" v-model="row2.excursion_print_title" @returned="enterPressed"></contenteditable>
                          </td>

                          <td class="bt-time">
                            <div class="time_fuck" v-for="(to, ri) in row2.preparedDatesTime" v-bind:key="ri">
                              {{ to.n || to.o }}
                            </div>
                          </td>
                          <td>
                            <template v-if="row2.excursion_prepay_allow === 'true'">
                              {{ row2.excursion_prepay_adult }}/{{ row2.excursion_prepay_children }}
                            </template>
                            <template v-else>
                              {{ row2.excursion_price_adult }}/{{ row2.excursion_price_children }}
                            </template>
                          </td>
                          <td>
                            <contenteditable :contenteditable="true" tag="div" :no-html="false" v-model="row2.excursion_print_price" @returned="enterPressed"></contenteditable>
                          </td>
                          <td v-for="(date, index) in dates" v-bind:key="index" class="center">
                            <div class="time_fuck" v-for="(to, ri) in row2.preparedDatesTime" v-bind:key="ri">
                              <template v-if="row2.preparedDates && row2.preparedDates[convertDate(date, 'YYYY-MM-DD')]">
                                <div>
                                  <div v-for="(d, index) in row2.preparedDates[convertDate(date, 'YYYY-MM-DD')]" v-bind:key="index">
                                    <i v-if="to.o === convertDate(d.date, 'HH:mm')" :data-time="convertDate(d.date, 'HH:mm')">☑</i>
                                  </div>
                                </div>
                              </template>
                            </div>

                          </td>
                          <td style="text-align: center">{{ prepareDurationH(row2.excursion_duration) }}</td>
                        </tr>
                      </template>
                    </template>
                  </template>
                </template>
                </tbody>
              </table>
              <Pager />
            </Report>
          </div>
          <div class="mini-box">
            <div style="margin: 0 0 2px 0;">
              <b contenteditable="true" style="font-size: 14px;">
                Ежедневно: морские прогулки, дельфинарий "Немо", джиппинг, квадроциклы, дайвинг.
              </b>
            </div>
            <Report key="report"
                    ref="report"
                    url="flights/report"
                    :def-params="{order: {field: 'excursion_id', order: 'desc'}, filters: {schedule_date: 'bw|' + period, excursion_publication: 'eq|enabled'}, limit: 500}" v-on:loading="reportLoading" v-on:done="reportLoaded">
              <Loading />
              <div v-if="error">
                {{error}}
              </div>
              <table v-else class="table-print" cellpadding="0" cellspacing="0" width="100%">
                <thead>

                <tr>
                  <td>
                    <h2 style="margin: 0 0 8px">
                      ТЭФ &laquo;Визит&raquo;
                    </h2>
                    <div>
                      <b contenteditable="true">{{ point_data.address || '' }}</b>
                    </div>
                  </td>
                  <td rowspan="2" class="vertical-text_td">
                    <div class="vertical-text x2">
                      Время<br>отправления
                    </div>
                  </td>
                  <td rowspan="2" class="vertical-text_td">
                    <div class="vertical-text x2">
                      Цена<br>взр/дет
                    </div>
                  </td>
                  <td rowspan="2" class="vertical-text_td">
                    <div class="vertical-text">
                      Доп. сбор
                    </div>
                  </td>
                  <td colspan="7" style="text-transform: uppercase;text-align: center">
                    <div style="display: flex;align-items: center;justify-content: center">
                      <div class="qr-pay" style="margin: 0 20px 0 0">

                      </div>
                      <h3>

                        <template v-if="order_seller_data && order_seller_data.label">
                          <div contenteditable="true">
                            <div style="font-size: 22px;margin: 0 0 10px;">
                              {{formatPhone(order_seller_data.label.split('[')[1].replace(']', ''))}}
                            </div>
                            {{order_seller_data.label.split('[')[0]}}
                          </div>
                        </template>
                      </h3>
                    </div>


                  </td>
                  <td rowspan="2" class="vertical-text_td">
                    <div class="vertical-text">
                      Общее время
                    </div>
                  </td>
                </tr>
                <tr>
                  <td><b style="font-size: 14px">Сайт: vizit.tours</b></td>
                  <td v-for="(date, index) in dates" v-bind:key="index" style="text-align: center;text-transform: uppercase">
                    <b>{{convertDate(date, 'DD.MM')}}</b><br>
                    {{days[convertDate(date, 'd')]}}
                  </td>
                </tr>

                </thead>
                <tbody v-if="report && point_data && point_data.sub">
                <tr v-if="report.rows_count === 0">
                  <td colspan="16">
                    Не найдено.
                  </td>
                </tr>



                <template v-else>

                  <template v-for="(row, index) in report.rows">
                    <template v-if="row.excursion_linked === row.excursion_id && row.preparedDates">
                      <tr
                          v-if="row.excursion_linked === row.excursion_id && (point_data.sub === row.subdivision_id)"
                          v-bind:key="index"
                      >
                        <td>
                          <contenteditable :contenteditable="true" tag="div" :no-html="false" v-model="row.excursion_print_title" @returned="enterPressed"></contenteditable>
                        </td>

                        <td class="bt-time">
                          <div class="time_fuck" v-for="(to, ri) in row.preparedDatesTime" v-bind:key="ri">
                            {{ to.n || to.o }}
                          </div>
                        </td>
                        <td>
                          <template v-if="row.excursion_prepay_allow === 'true'">
                            {{ row.excursion_prepay_adult }}/{{ row.excursion_prepay_children }}
                          </template>
                          <template v-else>
                            {{ row.excursion_price_adult }}/{{ row.excursion_price_children }}
                          </template>
                        </td>
                        <td>
                          <contenteditable :contenteditable="true" tag="div" :no-html="false" v-model="row.excursion_print_price" @returned="enterPressed"></contenteditable>
                        </td>
                        <td v-for="(date, index) in dates" v-bind:key="index" class="center">
                          <div class="time_fuck" v-for="(to, ri) in row.preparedDatesTime" v-bind:key="ri">
                            <template v-if="row.preparedDates && row.preparedDates[convertDate(date, 'YYYY-MM-DD')]">
                              <div>
                                <div v-for="(d, index) in row.preparedDates[convertDate(date, 'YYYY-MM-DD')]" v-bind:key="index">
                                  <i v-if="to.o === convertDate(d.date, 'HH:mm')" :data-time="convertDate(d.date, 'HH:mm')">☑</i>
                                </div>
                              </div>
                            </template>
                          </div>
                        </td>

                        <td style="text-align: center">{{ prepareDurationH(row.excursion_duration) }}</td>
                      </tr>

                      <template v-for="(row2, index) in compareSubdivision(row.excursion_id)">
                        <tr
                            v-if="row.excursion_id !== row2.excursion_id && row2.subdivision_id === point_data.sub"
                            v-bind:key="index"
                        >
                          <td>
                            <contenteditable :contenteditable="true" tag="div" :no-html="false" v-model="row2.excursion_print_title" @returned="enterPressed"></contenteditable>
                          </td>

                          <td class="bt-time">
                            <div class="time_fuck" v-for="(to, ri) in row2.preparedDatesTime" v-bind:key="ri">
                              {{ to.n || to.o }}
                            </div>
                          </td>
                          <td>
                            <template v-if="row2.excursion_prepay_allow === 'true'">
                              {{ row2.excursion_prepay_adult }}/{{ row2.excursion_prepay_children }}
                            </template>
                            <template v-else>
                              {{ row2.excursion_price_adult }}/{{ row2.excursion_price_children }}
                            </template>
                          </td>
                          <td>
                            <contenteditable :contenteditable="true" tag="div" :no-html="false" v-model="row2.excursion_print_price" @returned="enterPressed"></contenteditable>
                          </td>
                          <td v-for="(date, index) in dates" v-bind:key="index" class="center">
                            <div class="time_fuck" v-for="(to, ri) in row2.preparedDatesTime" v-bind:key="ri">
                              <template v-if="row2.preparedDates && row2.preparedDates[convertDate(date, 'YYYY-MM-DD')]">
                                <div>
                                  <div v-for="(d, index) in row2.preparedDates[convertDate(date, 'YYYY-MM-DD')]" v-bind:key="index">
                                    <i v-if="to.o === convertDate(d.date, 'HH:mm')" :data-time="convertDate(d.date, 'HH:mm')">☑</i>
                                  </div>
                                </div>
                              </template>
                            </div>

                          </td>
                          <td style="text-align: center">{{ prepareDurationH(row2.excursion_duration) }}</td>
                        </tr>
                      </template>
                    </template>
                  </template>
                </template>
                </tbody>
              </table>
              <Pager />
            </Report>
          </div>
        </div>



        <button class="btn btn-primary hide-print" style="margin-top: 30px;margin-right: 30px" v-on:click="getSort">
          Sort
        </button>

        <button class="btn btn-primary hide-print" style="margin-top: 30px;display: none" v-on:click="save">
          Сохранить
        </button>
      </div>
    </div>


</template>


<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';
import AutosuggestMulti from "@/components/AutosuggestMulti";
import QRCodeVue3 from "qrcode-vue3";
import Input from "@/components/Input";
import moment from 'moment';
import Pager from "@/components/Pager";
import Report from "@/components/report/Report";
import $ from "jquery";
import contenteditable from 'vue-contenteditable'; // Not needed it registered globally
import Select from '../../../components/Select';

export default {
  mixins: [mixins],

  components: {
    Input,
    AutosuggestMulti,
    QRCodeVue3,
    Select,
    Pager,
    Report,
    contenteditable
  },

  watch: {
    'currentDate'() {
      let dateArray = [];
      let currentDate = this.currentDate;
      let i = 0;

      while (i < 7) {
        dateArray.push(new Date(currentDate));
        currentDate = moment(currentDate).add(1, 'days');
        i++;
      }

      this.dates = dateArray;
      this.period = moment(dateArray[0]).format('YYYY-MM-DD 00:00:00') + ';' + moment(dateArray[dateArray.length-1]).format('YYYY-MM-DD 23:59:59');
    this.getReport().changeReportParams({
      order: {
          field: 'excursion_id',
          order: 'desc'
      },
      filters: {
        schedule_date: 'bw|' + this.period,
        excursion_publication: 'eq|enabled'
      },
      limit: 500
    }
    )
    }
  },


  created() {
    let dateArray = [];
    let currentDate = this.currentDate;
    let i = 0;

    while (i < 7) {
      dateArray.push(new Date(currentDate));
      currentDate = moment(currentDate).add(1, 'days');
      i++;
    }

    this.dates = dateArray;
    this.period = moment(dateArray[0]).format('YYYY-MM-DD 00:00:00') + ';' + moment(dateArray[dateArray.length-1]).format('YYYY-MM-DD 23:59:59');
  },

  data() {
    return {
      selectDates: [['2024-07-08', '7 неделя'], ['2024-07-15', '8 неделя'], ['2024-07-22', '9 неделя'], ['2024-07-29', '10 неделя'], ['2024-08-05', '11 неделя'], ['2024-08-12', '12 неделя'], ['2024-08-19', '13 неделя'], ['2024-08-26', '14 неделя'], ['2024-09-02', '15 неделя'], ['2024-09-09', '16 неделя'], ['2024-09-16', '17 неделя']],
      order_seller_data: [],
      point_data: [],
      currentDate: '2024-09-16',
      days: [
        'Вс',
        'Пн',
        'Вт',
        'Ср',
        'Чт',
        'Пт',
        'Сб'
      ],
      period: '',
      show: false,
      showMini: true,
      show_a4_v: false,
      show_a4_x3: false,
      showBadge: false,
      count: 32,
      dates: [],
      bagePages: 4,
      names: [
        'Виктория Ивановна',
        'Татьяна',
        'Любовь Георгиевна',
        'Зоя Константиновна',
        'Наталья',
        'Светлана',
        'Елена',
        'Валентина',
        'Светлана',
        'Ольга',
        'Галина',
        'Наталья',
        'Наталья',
        'Светлана Григорьевна',
        'Анастасия',
        'Татьяна',
        'Евгения',
        'Людмила',
        'Тиночка',
        'Тина Борисовна',

        'Галина',
        'Галина',
        'Галочка',
        'Елена',
        'Оксана',
        'Раиса',
        'Светлана',
        'Ольга Анатольевна',
        'Елена',
        'Елена',
        'Лионелла',
        'Оксана',

      ],
      account_data: [],
      tags:[
        'Абрау-Дюрсо',
        'Водопады',
        'Дольмены',
        'Джиппинг',
        'Геленджик',
        'Тур по Черноморскому побережью',
        'Азовское море',
        'Святые места',
        'Сочи',
        'Абхазия',
        'Вечерние шоу',
      ],
      data: {

      },
      loading: false,
      error: null,
      report: null
    }
  },

  methods: {

     save() {
      console.log('--- save start ----')
      this.report.rows.forEach(async (i) => {

        await Ajax.post(`/settings/excursion/save_print/${i.excursion_id}`, {
          excursion_id: i.excursion_id,
          excursion_print_title: i.excursion_print_title,
          excursion_print_price: i.excursion_print_price,
          excursion_print_sort: i.excursion_print_sort
        });


      })
      console.log('--- save end ----')
    },

    changeSellerIds(val){
      if (val) {
        this.order_seller_data = val
      }
    },

    compareSubdivision(id) {
      return this.report.rows.filter(i => i.excursion_linked === id).sort((a, b) => a.excursion_sort_a - b.excursion_sort_a);
    },

    getShowLinked(eid) {
      let r = this.report.rows.find(i => i.excursion_link && i.excursion_link.indexOf(eid) > -1 );

      return r && r.show
    },


    getSort() {
      this.report.rows = this.report.rows.sort((a, b) => parseInt(a.excursion_print_sort || 1) - parseInt(b.excursion_print_sort || 1))
    },

    diff(date1, date2) {
      let a = new Date(date1).getTime(),
          b = new Date(date2).getTime(),
          diff = {};

      diff.milliseconds = a > b ? a % b : b % a;
      diff.seconds = diff.milliseconds / 1000;
      diff.minutes = diff.seconds / 60;
      diff.hours = diff.minutes / 60;
      diff.days = diff.hours / 24;
      diff.weeks = diff.days / 7;

      return diff;
    },

    changeAccountIds(){
      this.show = false

      setTimeout(() => {
        this.show = true
      }, 1)
    },

    reportLoaded(report) {
      if (report && report.response && report.response.rows) {

        $.each(report.response.rows, function (k, v) {

          let fxd = null

          report.response.rows[k].excursion_print_sort = report.response.rows[k].excursion_print_sort || 99999

          report.response.rows[k].excursion_print_title = report.response.rows[k].excursion_print_title || report.response.rows[k].excursion_title

          report.response.rows[k].excursion_print_price = report.response.rows[k].excursion_print_price || (report.response.rows[k].excursion_prepay_allow === 'true' ? (report.response.rows[k].excursion_price_adult-report.response.rows[k].excursion_prepay_adult) +'/'+ (report.response.rows[k].excursion_price_children-report.response.rows[k].excursion_prepay_children) : '')

          report.response.rows[k].excursion_sort = (report.response.rows[k].excursion_publication === 'disabled' ? 1000000 : 999999);

          if (v.dates) {

            if (v.dates && v.dates.length) {
              let newDates = {}

              $.each(v.dates, (t, date) => {
                let keys = Object.keys(date[0]);

                if (keys && keys.length) {
                  if (newDates[keys[0]]) {
                    newDates[keys[0]].tickets = newDates[keys[0]].tickets.concat(date[0][keys[0]].tickets)

                    newDates[keys[0]].count = newDates[keys[0]].count + date[0][keys[0]].count
                  } else {
                    newDates[keys[0]] = date[0][keys[0]]
                    if (date[0][keys[0]].tickets[0] === 0) {
                      newDates[keys[0]].tickets = []
                    }

                    //newDates[keys[0]].count = newDates[keys[0]].tickets ? newDates[keys[0]].tickets.length : 0
                  }

                }
              })

              v.dates = newDates
            }


            let date = {}


            $.each(v.dates, function (k2, v2) {

              if (typeof k2 !== 'string') {
                let t = Object.entries(v2[0])
                k2 = t[0][0]
                v2 = t[0][1]

                if (!fxd) {
                  fxd = {}
                }
                fxd[k2] = v2
              }

              if (date[k2.split(' ')[0]]) {
                date[k2.split(' ')[0]].push({
                  date: k2,
                  count: v2.count,
                  limit: v2.limit
                } )
              } else {
                date[k2.split(' ')[0]] = [{
                  date: k2,
                  count: v2.count,
                  limit: v2.limit
                }]
              }

              if (report.response.rows[k].excursion_sort >= 999999) {
                let minDate = parseInt(moment(this.dates[0]).format('YYYYMMDD'));
                let maxDate = parseInt(moment(this.dates[this.dates.length-1]).format('YYYYMMDD'));
                let currentDate = parseInt(k2.split(' ')[0].replace(/[^0-9]/g, ''))

                if (currentDate >= minDate && currentDate <= maxDate) {
                  report.response.rows[k].excursion_sort =  (report.response.rows[k].excursion_publication === 'disabled' ? 1000000000 : parseInt(k2.split(' ')[1].replace(/[^0-9]/g, '')));
                }
              }

            }.bind(this))
            if (fxd) {
              report.response.rows[k].dates = fxd;
            }
            report.response.rows[k].preparedDates = date;

            let timesFack = [];

            let prDates = Object.entries(date);

            if ( prDates && prDates.length ) {
              prDates.map(i => {
                let y = timesFack.find(q => q.o === this.convertDate(i[1][0].date, 'HH:mm'))
                if (!y) {
                  timesFack.push({
                    o: this.convertDate(i[1][0].date, 'HH:mm'),
                    n: ''
                  })
                }
              })
            }


            console.log(timesFack)
            report.response.rows[k].preparedDatesTime = timesFack

            console.log('-33333333')


          }

          if (v.excursion_link && v.excursion_link.length) {
            v.excursion_linked = []

            $.each(report.response.rows, function (k, i) {
              if (v.excursion_link.indexOf(i.excursion_id) > -1) {
                i.excursion_linked = v.excursion_id;
              } else if (v.excursion_id === i.excursion_id) {
                i.excursion_linked = v.excursion_id;
              }
            })
          }

        }.bind(this))

        report.response.rows = report.response.rows.sort((a, b) => {
          if (a.excursion_sort >= 999999) {
            return -1;
          }
          if (b.excursion_sort >= 999999) {
            return -1;
          }
          return a.excursion_sort - b.excursion_sort;
        })


        report.response.rows = report.response.rows.sort((a, b) => a.excursion_sort - b.excursion_sort)

        report.response.rows = report.response.rows.sort((a, b) => a.excursion_print_sort - b.excursion_print_sort)

        report.response.rows.forEach(i => {
          if (i.excursion_link && i.dates) {
            Object.entries(i.dates).forEach(d => {
              d[1].count_total = d[1].count;
              i.excursion_link.forEach(linked => {
                let count = report.response.rows.find(e => e.excursion_id === linked)
                if (count && count.dates && count.dates[d[0]]) {
                  d[1].count_total = d[1].count_total + (count.dates[d[0]].count || 0);
                }
              })
            })
          }
        })

        this.report = report.response;

        this.error = null;
      }

    },

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;
    },

    changePointIds(val){
      if (val) {
        this.order_point = val && val.value ? val.value : val;
        if (val && val.place) {
          this.place_data = val.place[0]
        } else {
          this.place_data = null
        }
      }

      if (this.report && this.report.rows) {
        this.report.rows.forEach((it, k) => {
          if (it.preparedDatesTime) {
            it.preparedDatesTime.forEach((ii, l2) => {
              if ( this.point_data.place && this.point_data.place.length) {
                let newTime = null;

                this.point_data.place.forEach((i) => {
                  if (it.place_time['t'+i.value]) {

                    let newDate = moment().hour(ii.o.split(':')[0]).minute(ii.o.split(':')[1]).seconds(0)
                    newTime = newDate.add(it.place_time ? it.place_time['t'+i.value] || 0 : 0, 'minutes').format('HH:mm')

                    this.report.rows[k].preparedDatesTime[l2].n = newTime

                    // console.log(l2)

                  } else {
                    // let tty = this.report.rows[k].preparedDatesTimeFree[l2]
                    // this.report.rows[k].preparedDatesTime[l2] = tty
                  }


                })


              }
            })
          }
        })
      }

    },

    async changeStatus(id, status) {

      if (status === 'deleted') {
        if (confirm("Вы подтверждаете удаление?")) {
          await Ajax.post(`/q/save/${id}`, {
            q_id: id,
            q_status: status
          });
        }
      } else {
        await Ajax.post(`/q/save/${id}`, {
          q_id: id,
          q_status: status
        });
      }

      this.getReport().reload();

    },

  }
}
</script>
<style>
.bage-item_logo {

}
.bage-item_logo .qr-logo {
  background: url(@/assets/images/logo-white2.svg) no-repeat 0 0 / 114%;
  margin: -7px 0 0 0;
  font-size: 16px;
  padding: 55px 0 0 77px;
  color: #ffffff;
  width: 100%;
  height: 92px;
}
.bage-item_text {
  font-size: 46px;
  font-weight: 800;
  line-height: 42px;
}
.bage-item_subtext {
  font-size: 20px;
  font-weight: 600;
}
.bage-item {
  text-align: center;
  width: 9.4cm;
  height: 5.6cm;
  background: #260D6E;
  margin: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5cm;
}
.bt-time {
  background: #f2f2f2;
  font-weight: 600;
}

.rwer {
  position: absolute;
  left: -38px;
  height: auto;
  margin: 0;
}
.rwer input {
  border: none;
  padding: 0;
  height: 12px;
  width: 41px;
  margin: -3px 0 0 0;
}
</style>
